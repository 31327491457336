/*  */
.add_course_page_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.add_course_page_title {
  font-weight: 500;
  font-size: 32px;
  line-height: 125%;
  color: #070707;
}

.course_daft_badge {
  padding: 8px 16px;
  width: 72px;
  height: 41px;
  background: #f2f4ff;
  border-radius: 4px;

  font-weight: 500;
  font-size: 16px;
  line-height: 155%;
  color: #111111;
}

.add_course_cancel_btn {
  padding: 10px 16px;
  width: 86px;
  height: 45px;
  background: #ffffff;
  border: 1px solid #ef466f;
  border-radius: 4px;

  font-weight: 500;
  font-size: 16px;
  line-height: 155%;
  color: #ef466f;
}

.add_course_save_btn {
  padding: 10px 16px;
  width: 114px;
  height: 45px;
  background: #ffffff;
  border: 1px solid #d5d8e8;
  border-radius: 4px;

  font-weight: 500;
  font-size: 16px;
  line-height: 155%;
  color: #111111;
}

.add_course_publish_btn {
  padding: 10px 16px;
  width: 90px;
  height: 45px;
  background: #0a84ff;
  border: 1px solid #0a84ff;
  border-radius: 4px;

  font-weight: 500;
  font-size: 16px;
  line-height: 155%;
  color: #ffffff;
}

.add_course_pages {
  display: flex;
  align-items: center;
  gap: 12px;
  margin: 32px 0;
}

.add_course_pages .add_course_pages_name_wrapper {
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;
}

.add_course_pages .add_course_pages_name_wrapper .add_course_pages_count {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  background: #f2f4ff;
  border-radius: 50%;
  font-weight: 500;
  font-size: 14px;
  line-height: 155%;
  color: #8f8f92;
}

.add_course_pages .add_course_pages_name_wrapper .add_course_pages_count_active {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  background: #0a84ff;
  border-radius: 50%;
  font-weight: 500;
  font-size: 14px;
  line-height: 155%;
  color: #ffffff;
}

.add_course_pages .add_course_pages_name_wrapper .add_course_pages_name {
  font-weight: 500;
  font-size: 14px;
  line-height: 155%;
  color: #070707;
}

.add_course_form_label {
  font-weight: 500;
  font-size: 16px;
  line-height: 155%;
  color: #070707;
  text-transform: capitalize;
}

.add_course_form_sublabel {
  font-weight: 400;
  font-size: 14px;
  line-height: 155%;
  color: #8f8f92;
}

.add_course_form_sublabel1 {
  font-weight: 600;
  font-size: 14px;
  line-height: 155%;
  color: #070707;
}

.add_course_form_input {
  background: #ffffff;
  border-radius: 4px;

  font-weight: 500;
  font-size: 16px;
  line-height: 155%;
  color: #070707;
}

.add_course_form_file_input {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
  min-height: 200px;
  background: #ffffff;
  border: 1px dashed #d5d8e8;
  border-radius: 4px;
}

.add_course_form_file_icon {
  width: 40px;
  height: 40px;
}

.add_course_form_file_text {
  font-weight: 400;
  font-size: 14px;
  line-height: 155%;
  color: #8f8f92;
  text-align: center;
}

.add_course_form_file_submit {
  padding: 8px 16px;
  background: #ffffff;
  border: 1px solid #d5d8e8;
  border-radius: 4px;
  margin-top: 16px;
  font-weight: 500;
  font-size: 14px;
  line-height: 155%;
  color: #111111;
}

.add_course_form_img_preview_wrapper {
  padding: 8px;
  background: #ffffff;
  border: 1px solid #d5d8e8;
  border-radius: 4px;
  margin-top: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.add_course_form_preview_img {
  width: 48px;
  height: 48px;
  border-radius: 4px;
}

.add_course_form_img_preview_name {
  font-weight: 500;
  font-size: 16px;
  line-height: 155%;
  color: #070707;
}

.add_course_form_img_preview_type {
  font-weight: 400;
  font-size: 14px;
  line-height: 155%;
  color: #8f8f92;
  text-transform: uppercase;
}

.add_course_form_img_preview_size {
  font-weight: 500;
  font-size: 16px;
  line-height: 155%;
  color: #8f8f92;
  text-transform: uppercase;
}

.add_course_form_label_second {
  font-weight: 400;
  font-size: 14px;
  line-height: 155%;
  display: flex;
  align-items: center;
  color: #8f8f92;
  margin: 0 0 8px;
}

.input-group-alternative-styled {
  box-shadow: none !important;
}

.input-addon {
  background: #f2f4ff !important;
  border-radius: 4px !important;
  font-weight: 400;
  font-size: 16px;
  line-height: 155%;
  color: #070707;
}

.course_add_requirement_input {
  background: #ffffff;
  border: 1px dashed #d5d8e8;
  border-radius: 4px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin: 12px 0;
}

.course_add_requirement_input img {
  cursor: pointer;
}

.course_add_requirement_title {
  font-weight: 500;
  font-size: 16px;
  line-height: 155%;
  color: #111111;
  cursor: pointer;
}

.add_course_form_back_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 16px 10px 12px;
  gap: 8px;
  width: 95px;
  height: 45px;
  background: #ffffff;
  border: 1px solid #d5d8e8;
  border-radius: 4px;

  font-weight: 500;
  font-size: 16px;
  line-height: 155%;
  color: #111111;
}

.add_course_form_continue_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--success);
  padding: 10px 12px 10px 16px;
  gap: 8px;
  width: 127px;
  height: 45px;
  background: var(--success);
  border-radius: 4px;

  font-weight: 500;
  font-size: 16px;
  line-height: 155%;
  color: #ffffff;
}

.add_course_form_continue_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #0a84ff;
  padding: 10px 12px 10px 16px;
  gap: 8px;
  width: 127px;
  height: 45px;
  background: #0a84ff;
  border-radius: 4px;

  font-weight: 500;
  font-size: 16px;
  line-height: 155%;
  color: #ffffff;
}

.add_course_save_continue_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--success);
  padding: 10px 12px 10px 16px;
  gap: 8px;
  width: 127px;
  height: 45px;
  background: var(--success);
  border-radius: 4px;

  font-weight: 500;
  font-size: 16px;
  line-height: 155%;
  color: #ffffff;
}

@media (max-width: 768px) {
  .add_course_page_header {
    flex-direction: column-reverse;
    align-items: flex-start;
    gap: 24px;
  }

  .add_course_page_title {
    font-size: 24px;
    line-height: 125%;
  }
}

@media (max-width: 425px) {
  .add_course_pages {
    gap: 2px;
  }

  .add_course_pages .add_course_pages_name_wrapper {
    gap: 4px;
  }

  .add_course_pages .add_course_pages_name_wrapper .add_course_pages_count {
    width: 24px;
    height: 24px;
    background: #f2f4ff;
    font-size: 10px;
  }

  .add_course_pages .add_course_pages_name_wrapper .add_course_pages_count_active {
    width: 24px;
    height: 24px;
    background: #0a84ff;
    font-size: 10px;
  }

  .add_course_pages .add_course_pages_name_wrapper .add_course_pages_name {
    font-size: 10px;
  }
}

/*  */
.course-content-wrapper {
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 16px;
  max-height: 90vh;
  background: #ffffff;
  border: 1px solid #d5d8e8;
  border-radius: 8px;
}

.course-section-wrapper {
  border: 1px solid #d5d8e8;
  border-radius: 4px;
  overflow-y: auto;
}

.course-section-head {
  display: flex !important;
  align-items: baseline !important;
  padding: 8px !important;
}

.course-section-head i {
  color: #8f8f92;
}

.lecture-section-wrapper {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  padding: 8px 0 8px 24px;
  cursor: pointer;
}

.lecture-section-wrapper.active,
.lecture-section-wrapper:hover {
  background: #f2f4ff;
}

.course-section-name,
.lecture-section-title {
  font-weight: 500;
  font-size: 16px;
  line-height: 155%;
  color: #111111;
}

.course-section-lecture,
.lecture-section-subtitle {
  font-weight: 400;
  font-size: 14px;
  line-height: 155%;
  color: #8f8f92;
}

.course-section-list:not(:last-child) {
  border-bottom: 1px solid #d5d8e8;
  border-radius: 0 !important;
}

.add-section-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 16px 10px 12px;
  margin: 16px 0;
  gap: 8px;
  background: #ffffff;
  border: 1px dashed #d5d8e8;
  border-radius: 4px;

  font-weight: 500;
  font-size: 16px;
  line-height: 155%;
  color: #111111;
}

.add-lecture-section-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 16px 10px 12px;
  margin: 16px 24px;
  gap: 8px;

  background: #ffffff;
  border: 1px dashed #d5d8e8;
  border-radius: 4px;

  font-weight: 500;
  font-size: 16px;
  line-height: 155%;
  color: #111111;
}

.form-content-wrapper-main {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.form-content-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  gap: 16px;
  background: #ffffff;
  border: 1px solid #d5d8e8;
  border-radius: 8px;
}

.form-content-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.form-content-count {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  width: 20px;
  height: 20px;
  background: #f2f4ff;
  border-radius: 32px;

  font-weight: 500;
  font-size: 14px;
  line-height: 155%;
  color: #070707;
}

.form-content-title {
  font-weight: 600;
  font-size: 16px;
  line-height: 155%;
  color: #8f8f92;
}

.form-content-remove {
  background: #ffffff;
  width: 30px;
  height: 30px;
  display: flex;
  padding: 5px;
  border: 1px solid #ef466f;
  border-radius: 4px;
  cursor: pointer;
}

.form-content-upload-type {
  display: flex;
  align-items: center;
  padding: 4px;
  gap: 4px;
  width: 100%;
  height: 40px;
  background: #f2f4ff;
  border-radius: 4px;
  margin-top: 40px;
  cursor: pointer;

  font-weight: 500;
  font-size: 16px;
  line-height: 155%;
  color: #8f8f92;
}

.form-content-upload-browse {
  width: 50%;
  text-align: center;
}

.active-type {
  padding: 2px 16px;
  background: #ffffff;
  border: 1px solid #d5d8e8;
  border-radius: 4px;
  font-weight: 500;
  font-size: 16px;
  line-height: 155%;
  color: #070707;
}

.form-content-upload-link {
  width: 50%;
  text-align: center;
}

/*  */
.input-group-link {
  box-shadow: none;
  border: 1px solid #d2d6da;
  border-radius: 4px;
  width: 100%;
  border: 1px solid #d2d6da;
  -webkit-appearance: none;
  appearance: none;
  transition: box-shadow 0.15s ease, border-color 0.15s ease;
}

.is_private_toggle {
  --width: 40px;
  --height: calc(var(--width) / 2);
  --border-radius: calc(var(--height) / 2);

  display: inline-block;
  cursor: pointer;
}

.toggle__input {
  display: none;
}

.toggle__fill {
  position: relative;
  width: var(--width);
  height: var(--height);
  border-radius: var(--border-radius);
  background: #dddddd;
  transition: background 0.2s;
}

.toggle__fill::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: var(--height);
  width: var(--height);
  background: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  border-radius: var(--border-radius);
  transition: transform 0.2s;
}

.toggle__input:checked~.toggle__fill {
  background: var(--primary);
}

.toggle__input:checked~.toggle__fill::after {
  transform: translateX(var(--height));
}

.text-trans {
  text-transform: capitalize;
}

.is-error {
  border: 1px solid red;
  border-radius: 4px;
}

.active-content {
  color: #070707;
  font-weight: 600;
}

.error-message {
  color: red;
}

.floating .lecture-section-wrapper {
  background-color: #f2f4ff;
}

.react_quill {
  max-height: 250px;
  height: 250px;
}