/* .announcement-toggle-btn {
    border: 2px solid var(--primary);
    display: inline-block;
    padding: 10px;
    margin: 0;
    position: relative;
    text-align: center;
    transition: background 600ms ease, color 600ms ease;
  }
  
  input[type=radio].announcement-toggle {
    display: none;
  }
  input[type=radio].announcement-toggle + label {
    cursor: pointer;
    min-width: 60px;
  }
  input[type=radio].announcement-toggle + label:hover {
    background: none;
    color: #1a1a1a;
  }
  input[type=radio].announcement-toggle + label:after {
    background: var(--primary);
    content: "";
    height: 100%;
    position: absolute;
    top: 0;
    transition: left 200ms cubic-bezier(0.77, 0, 0.175, 1);
    width: 100%;
    z-index: -1;
  }
  input[type=radio].announcement-toggle.announcement-toggle-left + label {
    border-right: 0;
  }
  input[type=radio].announcement-toggle.announcement-toggle-left + label:after {
    left: 100%;
  }
  input[type=radio].announcement-toggle.announcement-toggle-right + label {
    margin-left: -5px;
  }
  input[type=radio].announcement-toggle.announcement-toggle-right + label:after {
    left: -100%;
  }
  input[type=radio].announcement-toggle:checked + label {
    cursor: default;
    color: #fff;
    transition: color 200ms;
  }
  input[type=radio].announcement-toggle:checked + label:after {
    left: 0;
  } */


  
  .announcement-custom-checkbox {
    height: 45px;
    width: 150px;
  }
  .announcement-custom-checkbox input#announcement-status {
    display: none;
  }
  .announcement-custom-checkbox input#announcement-status + label {
    height: 100%;
    width: 100%;
  }
  .announcement-custom-checkbox input#announcement-status + label > .announcement-status-switch {
    cursor: pointer;
    width: 100%;
    height: 100%;
    position: relative;
    background-color: var(--gray);
    color: white;
    transition: all 0.5s ease;
    padding: 3px;
    border-radius: 3px;
  }
  .announcement-custom-checkbox input#announcement-status + label > .announcement-status-switch:before, .announcement-custom-checkbox input#announcement-status + label > .announcement-status-switch:after {
    border-radius: 2px;
    height: calc(100% - 6px);
    width: calc(50% - 3px);
    display: flex;
    align-items: center;
    position: absolute;
    justify-content: center;
    transition: all 0.3s ease;
  }
  .announcement-custom-checkbox input#announcement-status + label > .announcement-status-switch:before {
    background-color: white;
    color: black;
    box-shadow: 0 0 4px 4px rgba(0, 0, 0, 0.2);
    left: 3px;
    z-index: 10;
    content: attr(data-unchecked);
  }
  .announcement-custom-checkbox input#announcement-status + label > .announcement-status-switch:after {
    right: 0;
    content: attr(data-checked);
  }
  .announcement-custom-checkbox input#announcement-status:checked + label > .announcement-status-switch {
    background-color: var(--primary);
  }
  .announcement-custom-checkbox input#announcement-status:checked + label > .announcement-status-switch:after {
    left: 0;
    content: attr(data-unchecked);
  }
  .announcement-custom-checkbox input#announcement-status:checked + label > .announcement-status-switch:before {
    color: var(--primary);
    left: 50%;
    content: attr(data-checked);
  }