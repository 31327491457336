/* Modal */
.modal-head-title {
  font-weight: 500;
  font-size: 32px;
  line-height: 125%;
  color: #070707;
}

.quiz-body {
  height: 90vh;
  overflow-y: auto;
  position: relative;
}

.quiz-body::-webkit-scrollbar {
  width: 4px;
}

.quiz-body::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.quiz-body::-webkit-scrollbar-thumb {
  background: #c5c5c5;
}

.quiz-body::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.quiz-form-wrapper {
  border-top: 1px solid #d5d8e8;
  border-bottom: 1px solid #d5d8e8;
  padding: 12px 0;
}

.form-quiz-upload-type {
  display: flex;
  align-items: center;
  padding: 4px;
  gap: 4px;
  width: 100%;
  height: 49px;
  background: #f5f7f8;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  font-size: 16px;
  line-height: 155%;
  color: #8f8f92;
}

.form-quiz-upload-browse {
  width: 50%;
  text-align: center;
}

.active-type {
  padding: 2px 16px;
  background: #ffffff;
  border: 1px solid #d5d8e8;
  border-radius: 4px;
  font-weight: 500;
  font-size: 16px;
  line-height: 155%;
  color: #070707;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-quiz-upload-link {
  width: 50%;
  text-align: center;
}

.que-check,
.que-radio {
  height: 24px;
  width: 24px;
  cursor: pointer;
}

.add_quiz_form {
  margin: 36px 0;
}

.question_list_wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  background: #ffffff;
  border: 1px solid #d5d8e8;
  border-radius: 8px;
  min-height: 100%;
}

.question_list_box_wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: 35vh;
  overflow-y: auto;
}

.question_list_box_wrapper::-webkit-scrollbar {
  width: 12px;
  border-radius: 20px;
}

.question_list_box_wrapper::-webkit-scrollbar-track {
  background: #d5d8e8;
  border-radius: 20px;
}

.question_list_box_wrapper::-webkit-scrollbar-thumb {
  width: 16px;
  background: #fff;
  border: 3px solid #d5d8e8;
  border-radius: 15px;
}

.question_list_box_title {
  font-weight: 500;
  font-size: 16px;
  line-height: 155%;
  color: #8f8f92;
}

.question_list_title,
.question_list_box_subtitle {
  font-weight: 500;
  font-size: 16px;
  line-height: 155%;
  color: #070707;
  /* width: 100%; */
}

.question_list_box {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px;
  gap: 8px;
  background: #ffffff;
  border: 1px solid #d5d8e8;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 8px;
}
.isActiveQuestion{

}


.question_list_box:hover svg {
  color : #fff;
}

  .question_list_box:hover{
  background: #0a84ff;
  gap: 8px;
  padding: 12px;
  border: 1px solid transparent;
}

.question_list_number {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  width: 32px;
  padding: 4px 8px;
  border: 1px solid #d5d8e8;
  border-radius: 4px;
  background: #ffffff;
  font-weight: 400;
  font-size: 14px;
  line-height: 155%;
  color: #0a84ff;
}

.question_list_box:hover .question_list_title,
.question_list_box_selected .question_list_title {
  color: #ffffff;
}

.question_list_title {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-height: 21px;
  width: 100%;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.que-type-title {
  font-weight: 400;
  font-size: 16px;
  line-height: 155%;
  color: #8f8f92;
}

.list-question-name,
.que-type-content {
  font-weight: 600;
  font-size: 16px;
  line-height: 155%;
  color: #070707;
  text-transform: capitalize;
}

.list-que-type {
  font-weight: 400;
  font-size: 16px;
  line-height: 155%;
  color: #070707;
}

.complexity-badge {
  padding: 4px 12px;
  font-weight: 500;
  font-size: 16px;
  line-height: 155%;
  text-transform: capitalize;
  width: fit-content;
}

.complexity-bg-badge-success {
  background: #e5f8ec;
  color: #41b66a;
}

.complexity-bg-badge-warning {
  background: #fff6e0;
  color: #ffb443;
}

.complexity-bg-badge-danger {
  background: #ffecf0;
  color: #ef466f;
}

.list-question-tag {
  cursor: pointer;
  display: flex;
  padding: 2px 8px;
  gap: 8px;
  height: 30px;
  background: #ffffff;
  border: 1px solid #d5d8e8;
  border-radius: 4px;
  font-weight: 400;
  font-size: 16px;
  line-height: 155%;
  color: #070707;
  text-transform: capitalize;
}

.list-question-tag-active {
  cursor: pointer;
  background: var(--primary);
  border: 1px solid #d5d8e8;
  color: var(--white);
}
