.active2 {
    background-color: #afafefb5;

}

.flex-grow-org {
    flex-grow: 1;
}

.navbar-main-wrapper {
    overflow-x: hidden;
}

.navbar-main-wrapper::-webkit-scrollbar {
    width: 8px;
}

.navbar-main-wrapper::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.navbar-main-wrapper::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 50px;
}

.navbar-main-wrapper::-webkit-scrollbar-thumb:hover {
    background: #555;
}