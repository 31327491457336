/*  */
.download-wrap {
    display: flex;
    justify-content: end;
}

.result_subtitle {
    padding: 0.5rem !important;
    /* font-weight: 600; */
}
.result-progress-wrapper {
    width: 80px;
}
.progress-wrap {
    /* padding: 10px 100px; */
}
.result_header {
    /* font-weight: 600; */
}
.question-box {
    padding: 24px;
    width: 100%;
    border: 1px solid var(--lighter);
    /* max-height: 300px; */
    border-radius: 8px;
    margin-top: 20px;
    box-shadow: none;
}
.danger-text {
    color: #DC3A5A;
}
.success-text {
    color: #42CAA9
}
.question-subtitle {
    font-size: 10px;
}

.student-name {
    font-size: 32px;
    font-weight: 600;
    color: var(--primary);
}

.report-exam-details-wrapper {
    border-top: 1px solid var(--lighter);
    padding-top: 16px;
    margin-top: 8px;
    width: fit-content;
}

.report-exam-details-label {
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    color: var(--light);
}

.report-exam-details-label-min {
    font-size: 14px;
    font-weight: 500;
    color: var(--light);
}

.report-exam-name {
    max-width: 450px;
}

.report-exam-details-info {
    font-size: 16px;
    font-weight: 600;
    color: #070707
}

.question-name {
    font-size: 18px;
    font-weight: 600;
    color: var(--gray)
}

.challenge-exam-answer,
.exam-options {
    font-size: 16px;
    font-weight: 600;
    color: var(--gray-dark)
}

.challenge-exam-answer-invalid {
    color: var(--red);
}

.challenge-exam-answer-valid {
    color: var(--green);
}

.wrong-ans {
    color: var(--red);
    font-size: 20px;
}

.right-ans {
    color: var(--green);
    font-size: 20px;
}

.info-ans {
    color: var(--lighter);
    font-size: 20px;
}

.get-score {
    font-size: 20px;
    font-weight: 600;
    line-height: 1;
}
.total-score {
    font-size: 16px;
    font-weight: 600;
    line-height: 1;
}

.exam-challenge {
    font-size: 16px;
    font-weight: 500;
    line-height: 1;
    background: #E9F7EE;
    color: #3C4041;
    padding: 4px 8px;
    border-radius: 4px;
}

.challenge-question-tag {
    font-size: 16px;
    font-weight: 600;
    line-height: 1;
    background: #fff;
    border: 1px solid var(--lighter);
    color: #3C4041;
    padding: 8px;
    display: flex;
    align-items: center;
    border-radius: 4px;
}

.challenge-exam-no-answer {
    font-size: 14px;
    font-weight: 400;
    color: var(--gray);
}

.challenge-question-wrapper {
    border-top: 1px solid var(--lighter);
    margin-top: 16px;
    padding: 16px 8px;
}